// Initialize first
@import '../init';

.jobs-v1 {
  // Section Hero
  .hero {
    .hero-wrap {
      margin-top: toRem(166);
      margin-bottom: toRem(80);
      display: flex;
      flex-direction: row;
      gap: toRem(64);

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: center;
        gap: toRem(48);
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        padding: 0 toRem(20);
      }

      .hero-btn-wrap {
        width: fit-content;

        @include media-breakpoint-down(lg) {
          width: 100%;
          display: none;
        }

        &--tab {
          display: none;

          @include media-breakpoint-down(lg) {
            display: block;
          }
        }
      }

      .hero {
        &__left {
          color: $color-white;
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: toRem(48);

          @include media-breakpoint-down(lg) {
            width: 100%;
          }

          .hero-text-container {
            max-width: 564px;
            display: flex;
            flex-direction: column;
            gap: toRem(24);

            @include media-breakpoint-down(lg) {
              text-align: center;
              max-width: 100%;
            }
          }
        }

        &__right {
          position: relative;
          width: 50%;
          border-radius: 10px;
          box-shadow: $shadow-sm;
          overflow: hidden;

          @include media-breakpoint-down(lg) {
            margin-bottom: toRem(48);
            width: 100%;
          }

          @include media-breakpoint-down(sm) {
            border-radius: unset;
          }

          img {
            width: 100%;
            aspect-ratio: 1.28 / 1;

            @include media-breakpoint-down(lg) {
              width: 100%;
            }
          }

          p {
            position: absolute;
            top: toRem(16);
            left: toRem(16);
          }
        }
      }
    }
  }

  // Section Work List
  .work {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;

    @include media-breakpoint-down(lg) {
      padding-top: 64px;
      padding-bottom: 64px;
    }

    &__img {
      position: absolute;
      left: 0px;
      pointer-events: none;

      &--v1 {
        bottom: -32px;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
      &--v2 {
        top: 40px;
        display: none;

        @include media-breakpoint-down(lg) {
          display: block;
        }

        @include media-breakpoint-down(sm) {
          top: 0px;
        }
      }
    }

    &__flex {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 32px;

      @include media-breakpoint-down(sm) {
        gap: 48px;
      }
    }
    &__form {
      position: relative;
      width: 100%;

      @include media-breakpoint-down(sm) {
        padding: 0 20px;
      }
    }
    &__warp {
      position: relative;
      display: flex;
      gap: 20px;

      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
      }

      .items {
        &__inputs {
          &--input::placeholder {
            color: $color-light-grey;
          }
        }
      }
    }

    &__departemen {
      width: 262px;

      @include media-breakpoint-down(xl) {
        width: unset;
        flex: 1;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__type {
      width: 182px;

      @include media-breakpoint-down(xl) {
        width: unset;
        flex: 1;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__location {
      width: 218px;

      @include media-breakpoint-down(xl) {
        width: unset;
        flex: 1;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__times {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 24px;

      @include media-breakpoint-down(sm) {
        padding: 0px 20px;
      }

      &__time {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 32px;
        background: $color-white;
        box-shadow: 8px 16px 52px rgba(126, 138, 253, 0.08);
        border-radius: 10px;

        @include media-breakpoint-down(lg) {
          justify-content: unset;
          flex-direction: column;
          gap: 16px;
        }

        @include media-breakpoint-down(sm) {
          padding: 20px 16px;
        }
      }
    }

    &__left {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 50%;

      @include media-breakpoint-down(lg) {
        justify-content: unset;
        flex-direction: column;
        gap: 6px;
      }
    }

    &__right {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 50%;
      padding-left: toRem(128);

      @include media-breakpoint-down(lg) {
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 100%;
        padding-left: 0;
      }
    }

    &__working {
      position: relative;
      width: 330px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      p {
        color: $color-black;
      }
    }

    &__location-work {
      position: relative;
      display: flex;
      height: 22px;
      width: 125px;
      gap: 4px;

      @include media-breakpoint-down(sm) {
        height: unset;
        width: 100%;
      }

      p {
        color: $color-grey;
      }

      img {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }

    &__time-work {
      position: relative;
      display: flex;
      height: 22px;
      width: 100px;
      gap: 4px;

      @include media-breakpoint-down(sm) {
        height: unset;
      }

      p {
        color: $color-grey;
      }
    }
    &__departemen-work {
      position: relative;
      display: flex;
      width: 110px;
      height: 22px;
      gap: 4px;

      @include media-breakpoint-down(sm) {
        height: unset;
      }

      p {
        color: $color-grey;
      }
    }
  }

  .jobs {
    @include media-breakpoint-down(lg) {
      margin-bottom: 0px !important;
    }
  }

  .p-jobs {
    @include media-breakpoint-down(sm) {
      padding: 0px !important;
    }
  }

  .hero-jobs {
    @include media-breakpoint-down(lg) {
      margin-top: toRem(136) !important;
      margin-bottom: toRem(64) !important;
    }

    @include media-breakpoint-down(sm) {
      padding: 0px !important;

      .hero__left {
        padding: 0px 20px !important;
      }

      .hero-btn-wrap {
        padding: 0px 20px !important;
      }
    }
  }

  .jobs-img-filter {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
      cursor: pointer;
    }
  }

  .work-filter {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);

    &.active {
      display: block;
    }
    &__filter {
      position: relative;
      height: 700px;
      width: 100%;
      background: $color-white;
      margin-top: 220px;
      border-radius: 10px 10px 0px 0px;

      h3 {
        color: $color-black;
        width: 100%;
        padding: 19px 20px;
        border-bottom: 1px solid $color-light-grey;
      }
    }

    &__menu {
      position: relative;
      width: 100%;
      padding: 24px 20px;

      ul {
        padding-left: 0px;
        margin-bottom: 0px;
      }

      p.text-l-bold {
        color: $color-black;
      }

      &__list {
        position: relative;
        background: $color-white;
        width: 100%;
        list-style: none;
        border-radius: 10px;
        margin-top: 24px;

        &__drop {
          position: absolute;
          left: 6px;
          top: 2px;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          border-right: 1px solid $color-black;
          border-bottom: 1px solid $color-black;
        }

        li {
          margin-top: 16px;
          display: block;

          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .black {
    color: $color-black;
    font-weight: bold;
  }

  // Dropdown Settings
  .work__departemen,
  .work__type,
  .work__location {
    .drop__ul {
      z-index: 2;
      box-shadow: $shadow-sm;
    }
  }

  //   Custom Button
  //   Button Show More
  .btn--show-more {
    padding: 0;
    color: $color-black;
    gap: toRem(12);

    .icon-arr-down {
      width: 24px;
      height: 24px;
      background-image: url('../../media/images/icons/arrow-ios-down-black.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover {
      color: $color-grey;

      .icon-arr-down {
        background-image: url('../../media/images/icons/arrow-ios-down-grey.svg');
      }
    }

    // When Active
    &.active {
      .icon-arr-down {
        transform: rotate(180deg);
      }
    }
  }

  //   Bottom Sheet Styling
  .sheet-filter {
    .downupPopup-content {
      .filter-sheet {
        padding: toRem(24) toRem(20);
        display: flex;
        flex-direction: column;
        gap: toRem(24);

        .checkboxes {
          display: flex;
          flex-direction: column;
          gap: toRem(16);
          align-items: flex-start;
        }
      }
    }
  }
}
